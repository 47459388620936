.modalContainer {
  border-radius: 30px;
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

.result-image-container img {
  max-width: 550px;
  width: 100%;
}

.download-btn {
  position: absolute;
  bottom: 20px;
  left: 45%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  box-shadow: 5px 2px 10px 4px rgba(0, 0, 0, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.closeIcon {
  display: none;
}
